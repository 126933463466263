// import { useReducer } from 'react';

// function reducer(state, action) {
//   return {
//     ...state,
//     [action.name]: action.value
//   };
// }

// export default function useInputs(initialForm) {
//   const [state, dispatch] = useReducer(reducer, initialForm);
//   const onChange = e => {
//     dispatch(e.target);
//   };
//   return [state, onChange];
// }
import { useState, useCallback } from 'react';
function useInputs(initialForm) {
  const [form, setForm] = useState(initialForm);
  const onChange = useCallback(e => {
    const { name, value } = e.target;
    setForm(form => ({ ...form, [name]: value }));
  }, []);
  const reset = useCallback(() => setForm(initialForm), [initialForm]);
  return [form, onChange, reset];
}
export default useInputs;
