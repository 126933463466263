import Main from './Main';
import Contact from './Contact';
import Detail from './Detail';
import Work from './Work';
import Preview from './Preview';

export default {
  Main,
  Contact,
  Detail,
  Work,
  Preview
};
