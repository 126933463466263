/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import comp from 'components';
import config from 'config';
import DetailMembers from '../components/DetailMembers';
import DetailItem from '../components/Details';

let prevId;
let current = 0;
let heading = 0;
let isLast = false;

const Detail = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const [readyToPaging, setReadyToPaging] = useState(false);
  const [nextCaseDetail, setNextCaseDetail] = useState(null);
  const [detail, setDetail] = useState(null);
  const [nextOn, setNexton] = useState(false);
  let { id } = useParams(); // const { match } = props;// const { id } = match.params;
  const history = useHistory();
  const { isMime } = config;
  const { Works } = config.context;

  const paging = (e) => {
    if (isLoading) return;
    if (e.deltaY > 0 && config.isNext) {
      if (e.deltaY > 0 && config.isNext) next();
    } else if (e.deltaY < 0 && config.isPrev) prev();
    if (e.pageY >= document.body.offsetHeight * 0.96) {
      setNexton(true);
    } else {
      setNexton(false);
    }
  };

  const handleTouchStart = (e) => {
    return;
    // touchClientY = e.touches[0].clientY;
  };
  const handleTouchEnd = (e) => {
    return;
    // let deltaY;
    // deltaY = e.changedTouches[0].clientY - touchClientY;
    // if (deltaY < 0 && config.isNext) next();
    // else if (deltaY > 0 && config.isPrev) prev();
  };
  const getTop = (e) => {
    if (e > 0.96) {
      return setReadyToPaging(true);
    }
    setReadyToPaging(false);
  };
  const next = () => {
    if (readyToPaging) {
      setReadyToPaging(false);
      setPaging('next');
      return (config.isNext = false);
    }
  };
  const prev = () => {
    if (readyToPaging) {
      setReadyToPaging(false);
      setPaging('prev');
      return (config.isPrev = false);
    }
    setReadyToPaging(true);
    return;
  };

  const setPaging = (direction) => {
    setisLoading(true);
    let max = 0;
    try {
      max = isMime.length;
      if (max === 0) {
        return history.push(`/`);
      }
      if (direction === 'next') {
        prevId = current;
        heading = isMime[current + 1];
        if (isLast) {
          return setisLoading(false); //eof
        }
        document.body.classList.add('fixed');
        setTimeout(() => {
          document.body.classList.remove('fixed');
          const idx = parseInt(heading, 10);
          return history.push(`/detail/${idx}`);
        }, config.nextDelay);
      }
      if (direction === 'prev') {
        return setisLoading(false);
      }
    } catch (e) {}
  };

  const hasNext = () => {
    const max = isMime.length - 1;
    heading = isMime[current + 1];
    const result = !heading ? false : true;
    if (result) setNextCaseDetail(Works[heading]);
    else {
      setNextCaseDetail(null);
    }
    return result;
  };
  /**
   *
   */
  useEffect(() => {
    current = isMime.findIndex((v) => v === parseInt(id, 10));
    if (current === -1) return history.push(`/`);
    if (current != null) {
      try {
        setDetail(Works[parseInt(id, 10)]);
      } catch (e) {
        return history.push(`/`);
      }
      isLast = !hasNext();
      if (prevId && current < prevId) {
        // setTimeout(() => {}, 150);
        // const h = document.documentElement.scrollHeight - window.innerHeight;
        // window.scrollTo(0, h);
      } else {
        // window.scrollTo(0, 0);
      }
      window.scrollTo(0, 0);
      return () => {
        window.scrollTo(0, 0);
        setisLoading(false);
      };
    }
  }, [id, detail]);
  /**
   * render d
   */
  return detail ? (
    <>
      {isLoading ? undefined : <comp.Floating top={props.top} callback={getTop} />}
      <div className="olive detail" onWheel={paging} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} style={isLast ? { paddingBottom: 0 } : undefined}>
        <comp.Masthead data={detail} isLoading={isLoading} />
        {isLoading ? <></> : <DetailItem data={detail} top={props.top} isLoading={isLoading} />}
        <DetailMembers data={detail} />
        {nextCaseDetail ? (
          <comp.NextCase nextCase={nextCaseDetail} isActive={isLoading} readyToPaging={readyToPaging} parentCallback={next} on={nextOn} />
        ) : (
          <comp.NextCase isActive={isLoading} />
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

export default Detail;
