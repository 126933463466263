/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import config from 'config';
import { motion, useViewportScroll, useTransform } from 'framer-motion';

const Floating = ({ top, callback }) => {
  const dragBar = useRef();

  const { scrollYProgress } = useViewportScroll();

  const yRange = useTransform(scrollYProgress, [0, 0.99], [0, 1]);
  useEffect(() => {
    return () => {
      scrollYProgress.destroy(0, 0);
    };
  }, [scrollYProgress]);
  const getPercent = (current, total) => current / total;
  useEffect(() => {
    const total = document.documentElement.scrollHeight - document.documentElement.offsetHeight;
    const scrollTop = Math.ceil(window.scrollY);
    const percent = getPercent(scrollTop, total);

    if (callback) callback(percent);
    percent === 0 ? (config.isPrev = true) : (config.isPrev = false);
    percent >= 1 ? (config.isNext = true) : (config.isNext = false);
    scrollTop > 50 ? dragBar.current.classList.add('scroll') : dragBar.current.classList.remove('scroll');
  }, [top]);

  const onClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <div className="drag-bar link" ref={dragBar}>
      <svg className="drag-proxy-btn " viewBox="0 0 60 60" width="58px" height="58px" onClick={onClick}>
        <path fill="none" strokeWidth="4" strokeDasharray="0" d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0" className="proxy-bg" />
        <path className="arrow" fill="none" strokeWidth="4" d="M15,30 L 25,20 L 35,30" />
        <motion.path
          fill="none"
          strokeWidth="4"
          strokeDasharray="0 1"
          d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
          style={{
            pathLength: yRange,
            rotate: 90,
            translateX: 5,
            translateY: 5,
            scaleX: -1 // Reverse direction of line animation
          }}
        />
      </svg>
    </div>
  );
};
export default React.memo(Floating);
