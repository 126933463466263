import React from 'react';
import comp from 'components';
import config from 'config';

/**
 *
 * @param {*} props
 */

const DetailItem = ({ data, top, isLoading }) => {
  if (!data) return <></>;

  const { Contents, BackgroundColor } = data;

  const TextTypeB = (text) => (
    <div className="center">
      <div className="text">{text}</div>
    </div>
  );

  const mediaType = (media) => {
    let template;
    return media.File.map((file, i) => {
      const url = `${config.domain}${file.Uri}`;

      if (file.Mime === 'video/mp4') {
        template = (
          <div
            dangerouslySetInnerHTML={{
              __html: `
            <div>
              <video loop autoplay muted playsinline webkit-playsinline class="${`bg-video ${
                media.IsPadding ? 'full' : 'center'
              }`}" key=${i} src=${url}  src=${url} type="video/mp4" >
                <source src=${url} type="video/mp4" />
              </video>
            </div>
          `
            }}></div>
        );
      } else {
        template = <img className={media.IsPadding ? 'full' : 'center'} alt={`imgType`} src={url} />;
      }
      return (
        <div key={i} className="video_wrap">
          {template}
        </div>
      );
    });
  };
  return isLoading ? (
    <></>
  ) : (
    <div className="detail-item" style={{ backgroundColor: `${BackgroundColor}` }}>
      {Contents.map((r, i) =>
        r.Type === 'TXT' ? (
          <comp.Animate top={top} animationName="fadeInUp delay" key={i} c={r}>
            <div style={r.PickColor ? { color: r.PickColor } : undefined}>{TextTypeB(r.Content)}</div>
          </comp.Animate>
        ) : (
          <comp.Animate top={top} animationName="fadeInUp delay" key={i}>
            {mediaType(r)}
          </comp.Animate>
        )
      )}
    </div>
  );
};

export default DetailItem;
