import React, { useState, useEffect, useCallback } from 'react';

import comp from 'components';

function Preview() {
  const [detail, setDetail] = useState(null);

  /*
  const detail = config.dummy;
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  console.log(params.get('query'));
  */
  /**TEST */
  // const win = window.open('http://localhost:3000/preview', 'popup', 'height=300px, width=500px');
  // win.postMessage(config.dummy,'http://localhost:3000');

  const receiveMessage = useCallback(
    (event) => {
      // if (event.origin !== 'http://example.org:8080') return;
      console.log(event);
      const { data } = event;
      console.log(data);
      if (detail) return window.removeEventListener('message', receiveMessage);
      if (data) setDetail(data);
    },
    [detail]
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [receiveMessage]);

  return (
    <>
      <div className="olive detail preview">
        <comp.Masthead data={detail} />
        <comp.Details data={detail} />
        <comp.DetailMembers data={detail} />
      </div>
    </>
  );
}

export default Preview;
