import React, { useCallback, useEffect, useRef } from 'react';

const Animate = (props) => {
  const { top, animationName } = props;
  const elemRef = useRef();

  useEffect(() => {
    if (getViewportTop(elemRef.current, top)) animateCSS(elemRef.current, animationName);
    else animateCSS(elemRef.current);
  });

  const getViewportTop = useCallback((elem, viewportTop) => {
    const viewportBottom = viewportTop + window.innerHeight;
    const elemTop = Math.round(elem.offsetTop);
    const elemBottom = elemTop + elem.clientHeight;
    const isRender = elemTop - 30 < viewportBottom && elemBottom > viewportTop;
    return isRender;
  }, []);

  const animateCSS = useCallback((element, animationName, callback) => {
    const node = element;
    let list = [];
    try {
      list = animationName.split(' ');
      node.classList.add('animated');
      for (const li of list) {
        node.classList.add(li);
      }
    } catch {
      return;
    }
  }, []);

  return (
    <div ref={elemRef} style={{ opacity: 0 }} className={props.className}>
      {props.children}
    </div>
  );
};
export default Animate;
