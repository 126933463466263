import React, { useEffect, useRef } from 'react';
import config from 'config';

const Masthead = ({ data, isLoading }) => {
  const elemRef = useRef();
  useEffect(() => {
    if (elemRef && elemRef.current) elemRef.current.play();
  }, [data]);
  if (!data) return <></>;
  if (!isLoading) {
    const { ProjectName, ClientName, Tag, EndDate, Background } = data;

    const textComp = (
      <div className="olive-content">
        <div className={`text-wrap on ${isLoading ? 'effectFadeAway' : 'animated fadeInUp'}`}>
          <h6>{ClientName ? ClientName : 'ClientName'}</h6>
          {/* br 태그 추가를 위한 dangerouslySetInnerHTML */}
          <div
            dangerouslySetInnerHTML={{
              __html: `
              <h1>${ProjectName.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br>')}</h1>
          `
            }}
          />
          <div>{Tag ? Tag.join(', ') : '웹,모바일 구축'}</div>
          <br />
          <p>{EndDate ? EndDate.split('-').reverse().join('.') : '08.2019'}</p>
        </div>
      </div>
    );
    const { Mime, Uri } = Background;
    const videoBg = (
      // <div className={`bg next`} style={{ position: 'absolute', backgroundImage: `url(${config.domain + Uri})` }}>
      <div className={`bg next`} style={{ position: 'absolute', backgroundImage: `url(${config.domain + Uri})` }}>
        {Mime === 'video/mp4' ? (
          <video ref={elemRef} className="bg-video" key={Uri} loop autoPlay muted playsInline>
            <source src={`${config.domain}${Uri}`} type="video/mp4" />
          </video>
        ) : (
          <></>
        )}
      </div>
    );
    return (
      <div className={`masthead olive`}>
        {videoBg}
        {textComp}
      </div>
    );
  }
};
export default React.memo(Masthead);
