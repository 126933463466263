import React, { useState, useRef, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useLocation } from 'react-router';
import './App.scss';
import pages from 'pages';
import comp from 'components';
import AnimatedCursor from './components/AnimatedCursor';
import { AnimatePresence } from 'framer-motion';
import useRequest from 'useRequest';
import config from 'config';
// import ScrollToTop from './ScrollToTop';

const App = (props) => {
  const wrap = useRef(null);
  const youtubeRef = useRef(null);
  const [response, loading, error] = useRequest(`${config.api}/portfolio/main`);
  const [youtube, setYoutube] = useState(false);
  const [cursorOption, setCursorOption] = useState({
    innerSize: 25,
    outerSize: 15
  });

  const { innerSize, outerSize } = cursorOption;

  const toggleYoutube = (url) => {
    setYoutube(!youtube);

    if (!youtube) {
      youtubeRef.current.src = url;

      setCursorOption({
        ...cursorOption,
        innerSize: 0,
        outerSize: 0
      });
    } else {
      youtubeRef.current.src = '';

      setCursorOption({
        ...cursorOption,
        innerSize: 25,
        outerSize: 15
      });
    }
  };

  useEffect(() => {}, [response, loading, error]);

  if (response) {
    console.log(response);
    config.context = response.data;
    const newArr = response.data.Works.reduce(function (acc, curr, index) {
      if (curr.Background.Mime && curr.isDisplay !== 'X' && curr.isYoutube !== '') {
        acc.push(index);
      }
      return acc;
    }, []);
    config.isMime = newArr;
  }

  return response ? (
    <div className="wrap" ref={wrap}>
      <div className={'youtube ' + (youtube ? 'block' : 'none')} onClick={toggleYoutube}>
        <iframe title="youtube-video" frameBorder="0" allow="autoplay" allowFullScreen ref={youtubeRef}></iframe>
      </div>
      <AnimatedCursor innerSize={innerSize} outerSize={outerSize} color="43, 191, 102" outerAlpha={0.4} />
      <AppContent toggleYoutube={toggleYoutube} setYoutube={setYoutube} />
    </div>
  ) : (
    <div>server_err</div>
  );
};
const AppContent = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const [top, setTop] = useState(0);
  const [onMobile, setOnMobile] = useState(false);
  const [isChrome, setisChrome] = useState(false);
  const [mainBlack, setMainBlack] = useState(false);
  const navRef = useRef(null);

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const handleScroll = (e) => {
    const linkHref = document.location.href.substr(document.location.href.length - 1);
    const yOffset = e.target.documentElement.scrollTop;
    const navHeight = navRef.current.querySelector('.nav__bar').clientHeight;
    setTop(yOffset);
    if (linkHref !== '/') return;

    if (window.innerHeight - navHeight < yOffset && mainBlack === false) {
      setMainBlack(true);
    } else if (yOffset <= 0) {
      setMainBlack(false);
    }
  };

  useEffect(() => {
    const browse = navigator.userAgent.toLowerCase();
    props.setYoutube(false);
    if (browse.indexOf('chrome') !== -1) setisChrome(true);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', isMobile);
    if (isMobile()) setOnMobile(true);
    setMainBlack(false);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', isMobile);
      if (!isMobile()) setOnMobile(false);
    };
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <Route
      render={({ location }) => (
        <AnimatePresence exitBeforeEnter initial={false}>
          <div onContextMenu={(e) => e.preventDefault()} ref={navRef}>
            {pathname === '/preview' ? undefined : (
              <>
                <comp.Nav.NavSide toggleYoutube={props.toggleYoutube} />
                <comp.Nav.Nav
                  toggleYoutube={props.toggleYoutube}
                  isStatic={pathname === '/' ? false : true}
                  isFull={pathname === '/' ? true : false}
                  top={top}
                  mainBlack={pathname === '/' ? mainBlack : true}
                  onMobile={onMobile}
                />
              </>
            )}
            <Switch location={location} key={location.pathname}>
              <Route exact path="/" render={() => <pages.Main top={top} mainBlack={mainBlack} onMobile={onMobile} isChrome={isChrome} />} />
              <Route path="/Contact" render={() => <pages.Contact top={top} />} />
              <Route path="/work" render={() => <pages.Work top={top} toggleYoutube={props.toggleYoutube} />} />
              <Route path="/detail/:id" render={(routeProps) => <pages.Detail top={top} {...routeProps} />} />
              <Route path="/preview" render={() => <pages.Preview />} />
            </Switch>
          </div>
        </AnimatePresence>
      )}
    />
  );
};

export default App;
