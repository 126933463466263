import Floating from './Floating';
import Bg from './Bg';
import Nav from './Nav';
import OliveSlick from './OliveSlick';
import OliveSlider from './OliveSlider';
import DetailMembers from './DetailMembers';
import Animate from './Animate';
import SideBar from './SideBar';
import Footer from './Footer';
import Privacy from './Privacy';
import Details from './Details';
import NextCase from './NextCase';
import Masthead from './Masthead';
import Test from './Test';

export default {
  Bg,
  Floating,
  DetailMembers,
  Nav,
  OliveSlick,
  OliveSlider,
  SideBar,
  Animate,
  Footer,
  Privacy,
  Details,
  NextCase,
  Masthead,
  Test
};
