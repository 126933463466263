import React from 'react';

/**
 *
//  * @param {*} props
//  */
const DetailMembers = ({ data }) => {
  if (!data) return <></>;
  const { DirectorMember, UiMember, GuiMember, MotionMember, DeveloperMember } = data;
  return (
    <div className="detail-members olive-content">
      {/* <h5>{`세상과 당신사이 중앙 그룹이 있습니다.`}</h5> */}
      <div>
        <h6>PROJECT MEMBER</h6>
        <table>
          <tbody>
            {DirectorMember ? (
              <tr>
                <td>Director</td>
                <td>{DirectorMember}</td>
              </tr>
            ) : undefined}
            {UiMember ? (
              <tr>
                <td>UX Designer</td>
                <td>{UiMember}</td>
              </tr>
            ) : undefined}
            {GuiMember ? (
              <tr>
                <td>UI Designer</td>
                <td>{GuiMember}</td>
              </tr>
            ) : undefined}
            {MotionMember ? (
              <tr>
                <td>UI Interaction Designer</td>
                <td>{MotionMember}</td>
              </tr>
            ) : undefined}
            {DeveloperMember ? (
              <tr>
                <td>Developer</td>
                <td>{DeveloperMember}</td>
              </tr>
            ) : undefined}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const DetailNext = (props) => {};

export default DetailMembers;
