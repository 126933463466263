import React from 'react';
import Slider from 'react-slick';
import config from 'config';

export default class OliveSlider extends React.Component {
  render() {
    const { list } = this.props;
    const settings = {
      // dotsClass: 'custom-dots'
      // accessibility: true,
      // touchMove: true,
      // swipe: true,
      // swipeToSlide: true,
      touchMove: true,
      swipe: true,
      arrows: true,
      pauseOnHover: false,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1500,
      dots: true,
      infinite: true,
      useTransform: true,
      slidesToScroll: 1,
      slidesToShow: 1
    };
    return (
      <Slider {...settings} className="slider-custom">
        {list.map((r, i) => (
          <div className="item-wrap" key={i}>
            {/* <div className="item" style={{ backgroundImage: `url(${r ? config.domain + r : '/img/dummy/img-olivestone-02.jpg'})` }} /> */}
            <div className="item" style={{ backgroundImage: `url(${r ? config.domain + r : '/img/dummy/img-olivestone-02.jpg'})` }} />
            {/* <img className="sm-max" src={`${config.domain}${r}`} alt={i} /> */}
          </div>
        ))}
      </Slider>
    );
  }
}
