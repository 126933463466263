import React from 'react';
import config from 'config';

// const increase = 1.22;backgroundSize: `${nextCase * increase * 100}%
const NextCase = ({ nextCase, isActive, readyToPaging, parentCallback, isMain }) => {
  if (!nextCase) return <></>;
  const { Background, ProjectName } = nextCase;
  //style={{ backgroundImage: `url(${Background && Background.Uri ? config.domain + Background.Uri : '/img/dummy/joongang-group_content_1.jpg'})` }}>
  const { Mime, Uri } = Background;
  const videoBg =
    Mime === 'video/mp4' || 'image/png' || 'image/jpg' ? (
      <div className="bg next" style={{ backgroundImage: `url(${config.domain + Uri})` }}>
        <video loop autoPlay muted className="bg-video" key={Uri}>
          <source src={`${config.domain}${Uri}`} type="video/mp4" />
        </video>
      </div>
    ) : (
      <></>
    );

  return (
    <div className={`next-case-container  ${isActive ? `active` : ''} ${isMain && readyToPaging ? 'readyToPaging' : ''}`} onClick={parentCallback}>
      <div className="next-case olive">
        {videoBg}
        <div className="olive-content next-case-content">
          <div>
            <h6>Next Project</h6>

            {/* <h6>Next Project</h6> */}
            <div className="next-case-line" />
          </div>
          <svg className="next-case-title">
            <text y="70%">{ProjectName ? ProjectName : 'ProjectName'}</text>
          </svg>
        </div>
      </div>
    </div>
  );
};
export default NextCase;
