import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import comp from 'components';
import config from 'config';
import { motion } from 'framer-motion';

const Main = (props) => {
  const [scene, setScene] = useState(0);
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);
  const [scrollHeightData, setScrollHeightData] = useState([]);
  const [enterScene, setEnterScene] = useState(false);
  const [nextCase, setNextCase] = useState(false);
  const [readyToPaging, setReadyToPaging] = useState(false);
  const [nextCaseActive, setnextCaseActive] = useState(false);
  const content = useRef(null);
  const videoBG = useRef(null);
  const history = useHistory();
  const { Works, Client, Office } = config.context;
  const { isMime } = config;

  const setLayOut = () => {
    const containerTag = content.current.querySelectorAll('.scene');
    const windowWidth = window.innerWidth;
    let heightData = [];
    containerTag.forEach((tag, index) => {
      heightData.push({
        container: tag,
        height: tag.offsetHeight,
        objs: tag.childNodes
      });
      if (tag.classList.contains('olive-footer')) {
        windowWidth > 600 ? (heightData[index].height += 460) : (heightData[index].height += 110);
      } //fixed 때문에 webtl 456px 추가 모바일 104px 추가
    });
    setScrollHeightData(heightData);
  };

  useEffect(() => {
    setLayOut();
    setTimeout(() => {
      content.current.querySelector('.scene .slogun').classList.add('anim');
    }, 400);
    window.addEventListener('resize', setLayOut);
    return () => window.removeEventListener('resize', setLayOut);
  }, []);

  const handleWheel = (e) => {
    if (e.deltaY > 0 && config.isNext) {
      return next();
    }
  };

  const mainWheel = (e) => {
    e.persist();

    const yOffset = e.pageY;
    if (props.onMobile) return;
    if (!props.isChrome) return;
    scrollLoop(yOffset);
  };

  const mainScroll = (e) => {
    const yOffset = e.target.documentElement.scrollTop;
    scrollLoop(yOffset);
  };

  useEffect(() => {
    if (props.onMobile || !props.isChrome) {
      window.addEventListener('scroll', mainScroll);
    }
    return () => {
      window.removeEventListener('scroll', mainScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollHeightData, prevScrollHeight, scene]);

  const scrollLoop = (yOffset) => {
    setEnterScene(false);
    setPrevScrollHeight(0);

    for (let j = 0; j < scene; j++) {
      setPrevScrollHeight((prevScrollHeight) => prevScrollHeight + scrollHeightData[j].height);
    }

    if (scrollHeightData[scene].height === undefined) {
      return;
    }
    if (scrollHeightData[scene].height !== undefined) {
      if (yOffset > prevScrollHeight + scrollHeightData[scene].height && enterScene === false) {
        setEnterScene(true);
        setScene((prevScene) => prevScene + 1);
      }
    } else {
      return;
    }
    if (yOffset < prevScrollHeight && enterScene === false) {
      setEnterScene(true);
      if (scene === 0) return; // 브라우저 바운스 효과로 인해 마이너스가 되는 것을 방지(모바일)
      setScene((prevScene) => prevScene - 1);
    }
    if (!enterScene) animPlay(yOffset);
    if (enterScene) return;
  };

  const calcValues = useCallback(
    (values, currentYOffset) => {
      let rv;
      // 현재 씬(스크롤섹션)에서 스크롤된 범위를 비율로 구하기
      const scrollHeight = scrollHeightData[scene].height;
      // const scrollRatio = currentYOffset / scrollHeight;

      // start ~ end 사이에 애니메이션 실행
      const partScrollStart = values[2].start * scrollHeight;
      const partScrollEnd = values[2].end * scrollHeight;
      const partScrollHeight = partScrollEnd - partScrollStart;

      if (currentYOffset >= partScrollStart && currentYOffset <= partScrollEnd) {
        rv = ((currentYOffset - partScrollStart) / partScrollHeight) * (values[1] - values[0]) + values[0];
      } else if (currentYOffset < partScrollStart) {
        rv = values[0];
      } else if (currentYOffset > partScrollEnd) {
        rv = values[1];
      }
      return rv;
    },
    [scene, scrollHeightData]
  );

  const animPlay = useCallback(
    (yOffset) => {
      const scrollHeight = scrollHeightData[scene].height;
      const currentYOffset = yOffset - prevScrollHeight;
      const scrollRatio = currentYOffset / scrollHeight;
      const values = scrollHeightData[scene];

      switch (scene) {
        case 0:
          if (window.innerWidth > 600) {
            if (scrollRatio >= 0.35) {
              if (values.objs[1].classList.contains('on')) return;
              values.objs[1].classList.add('on');
            }
          }

          if (window.innerWidth < 600) {
            if (scrollRatio >= 0.6) {
              if (values.objs[1].classList.contains('on')) return;
              values.objs[1].classList.add('on');
            }
          }

          return;
        case 1:
          const sliderTrack = values.objs[0].querySelector('.slick-track');
          const fifth = values.objs[1].querySelector('.fifth');
          const sliderTranslate = [0, -sliderTrack.offsetWidth, { start: 0.23, end: 1 }];

          if (scrollRatio >= 0.2) {
            sliderTrack.style.transform = `translate3d(${calcValues(sliderTranslate, currentYOffset)}px,0,0)`;
          } else if (scrollRatio <= 0.1) {
            sliderTrack.style.transform = `translate3d(0,0,0)`;
          } else if (scrollRatio <= 1) {
            sliderTrack.style.transform = `translate3d(0,${-sliderTrack.offsetWidth},0)`;
          }
          if (scrollRatio >= 0.85) {
            if (fifth.classList.contains('on')) return;
            fifth.classList.add('on');
          }

          return;

        // case 2:
        //   if (scrollRatio >= 0) {
        //     if (values.objs[0].classList.contains('on')) return;
        //     values.objs[0].classList.add('on');
        //   }
        //   return;
        default:
          return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prevScrollHeight, scene, scrollHeightData]
  );

  const next = () => {
    if (readyToPaging) {
      config.isNext = false;
      setReadyToPaging(false);
      setnextCaseActive(true);
      document.body.classList.add('fixed');
      setTimeout(() => {
        document.body.classList.remove('fixed');
        return history.push(`/detail/${isMime[0]}`);
      }, config.nextDelay);
    }
  };

  const getTop = (e) => {
    if (e > 0.94) {
      setReadyToPaging(true);
    }
    if (e > 0.94) {
      if (Works && Works.length > 0) {
        return setNextCase(Works[isMime[0]]);
      }
    }
    setReadyToPaging(false);
    setNextCase(false);
  };

  return (
    <div onWheel={handleWheel} ref={videoBG}>
      {nextCase ? <comp.NextCase nextCase={nextCase} isActive={nextCaseActive} readyToPaging={readyToPaging} parentCallback={next} isMain={true} /> : <comp.Bg />}
      <comp.SideBar top={props.top} />
      <comp.Floating top={props.top} callback={getTop} />
      <motion.div className={props.mainBlack ? 'olive main blackout' : 'olive main'} onWheel={mainWheel} ref={content} initial="initial" animate="in" exit="out">
        <div className="olive-content scene">
          <div className="slogun left" style={{ opacity: 0 }}>
            <div className="title">SINCE 2012 - NOW</div>
            <div className="desc_wrap">
              <div className="desc">
                {/* 올리브스톤은 <br />
                사용자경험에 대한 <br />
                본질을 연구하고 디자인하며, <br />
                고객과 함께 성장하는 <br />
                크리에이터 전문집단 입니다. */}
                올리브스톤은 <br />
                젊고 야무진 사람들로 구성된
                <br /> 디지털 솔루션 에이전시입니다.
              </div>
            </div>
            <div className="desc_wrap">
              <div className="content en">Olivestone is a digital solution agency composed of young and smart people.</div>
            </div>
          </div>
          <div className="third right">
            <div className="desc_wrap">
              <div className="ko lg">
                사용자 경험을 기획하고 디자인하며, <br />
                프로토타입 혹은, 개발하여 <br />
                실현시켜내는 일을 좋아합니다. <br />
                우리가 만들어낸 결과가 <br />
                IT와 세상을 연결한다는 것을 확신합니다.
              </div>
            </div>
            <div className="desc_wrap">
              <div className="ko md">
                사용자 경험을 기획하고 디자인하며, <br />
                프로토타입 혹은, 개발하여 <br />
                실현시켜내는 일을 좋아합니다. <br />
                우리가 만들어낸 결과가 <br />
                IT와 세상을 연결한다는 것을 확신합니다.
              </div>
            </div>
            <div className="desc_wrap">
              <div className="en lg">
                Plan and design the user experience. <br />
                I like prototyping, or developing and realizing it.
                <br />
                I'm convinced that the results we've created connect IT to the world.
              </div>
            </div>
            <div className="desc_wrap">
              <div className="en md">
                Plan and design the user experience. <br />
                I like prototyping, or developing and realizing it.
                <br />
                I'm convinced that the results we've created connect IT to the world.
              </div>
            </div>
          </div>
        </div>
        <div className="scene sticky-elem">
          <comp.OliveSlick />
          <div className="olive-content" style={{ margin: 0 }}>
            <div className="fifth left">
              <div className="ko lg here" style={{ overflow: 'hidden' }}>
                <div className="hiddenTop">
                  우리를 찾아 준 고객 뿐 아니라 <br />
                  고객의 고객 경험까지 공감하며 <br />
                  차별화된 디지털 경험을 만들어갑니다.
                  {/* <span>올리브스톤</span>은 <br />
                  다양한 산업, 플랫폼에서 부딪히고 습득하며
                  <br />더 나은 사용자 경험을 전파하고 있습니다. */}
                </div>
              </div>

              <div className="ko md here" style={{ overflow: 'hidden' }}>
                <div className="hiddenTop">
                  우리를 찾아 준 고객 뿐 아니라 <br />
                  고객의 고객 경험까지 공감하며 <br />
                  차별화된 디지털 경험을 만들어갑니다.
                  {/* <span>올리브스톤</span> <br />
                  다양한 산업, 플랫폼에서 부딪히고 습득하며
                  <br />더 나은 사용자 경험을 전파하고 있습니다. */}
                </div>
              </div>
              <div className="en" style={{ overflow: 'hidden' }}>
                <div className="hiddenBottom">
                  We create a differentiated digital experience by sympathizing <br /> with not only the client who visited us but also the consumer experience.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="olive-center scene">
          <div className="sixth">
            <div className="title">OUR POSITIONS</div>
            <div className="subtitle">
              {config.sixth.map((data, i) => {
                return (
                  <comp.Animate top={props.top} animationName="fadeInUp " key={i}>
                    <div className="item" key={i}>
                      {data.data}
                    </div>
                  </comp.Animate>
                );
              })}
            </div>
          </div>
          <div className="seventh">
            <div className="title">OUR CLIENTS</div>
            <div className="partners">
              {Client.map((r, i) => {
                return (
                  <comp.Animate top={props.top} animationName="fadeInUp " key={i}>
                    <div className="item" key={i}>
                      <img src={`${config.domain}${r.Uri}`} alt={r.Name} />
                    </div>
                  </comp.Animate>
                );
              })}
            </div>
          </div>
        </div>
        <div className="olive-bottom scene">
          <div className="olive-bottom-slider">
            <comp.OliveSlider list={Office} />
          </div>
          <div className="olive-footer scene">
            <comp.Footer />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Main;
