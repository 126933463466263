import React from 'react';
import { useHistory } from 'react-router-dom';

const Footer = ({ hidden }) => {
  const history = useHistory();
  return (
    <div className="olive-content footer" style={{ background: hidden ? 'tranparent' : 'black' }}>
      <div className="company left">
        <div className="desc_wrap">
          <div className="branch">Seoul</div>
        </div>
        <div className="desc_wrap">
          <div className="desc">
            우리는 언제든 같이 함께할수 있게 열려 있습니다. <br />
            귀하의 프로젝트를 도울수있는 방법을 원하든 저희 팀에 합류하길 원하든 언제든 문의하십시오.
          </div>
        </div>
        <div className="company__info__wrap">
          <div className="company__info">
            <div className="desc_wrap">
              <div className="addr_co">address</div>
            </div>
            <div className="desc_wrap">
              <div className="addr_ko">서울시 강남구 강남대로320 황화빌딩 10F 올리브스톤</div>
            </div>
            <div className="desc_wrap">
              <div className="addr_en">
                10F, 320, Gangnam-daero, <br />
                Gangnam-gu, Seoul, Republic of Korea
              </div>
            </div>
            <div className="desc_wrap">
              <div className="email_co">
                EMAIL <br />
                contact@olivestonelab.com
              </div>
            </div>
            <div className="desc_wrap">
              <div className="phone_co">
                PHONE <br />
                070.7854.1177
              </div>
            </div>
            <div className="copy_co">2020 by OLIVESTONE All Rights Reserved.</div>
          </div>
          {hidden ? undefined : (
            <div className="company__inbound link">
              <div className="company__inbound__wrap" onClick={() => history.push('contact')}>
                <div className="inbound_icon" />
                <div className="inbound_req">프로젝트의뢰</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
