import React, { useState, useEffect, useRef } from 'react';
import comp from 'components';
import useInputs from 'useInputs';
import axios from 'axios';
import config from 'config';
const guideText = '문의하실 내용을 작성해 주세요.';
const maxSizeMb = 50; // 50MB
const maxSize = maxSizeMb * 1024 * 1024;

const initialState = {
  Name: '',
  Business: '',
  Email: '',
  Tel: '',
  Budget: '',
  Schedule: '',
  Content: ''
};

const Contact = (props) => {
  const [isToggle, setToggle] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [files, setFiles] = useState([]);

  const [state, onChange, reset] = useInputs({
    Name: '',
    Business: '',
    Email: '',
    Tel: '',
    Budget: '',
    Schedule: '',
    Content: ''
  });
  const { Name, Business, Email, Tel, Budget, Schedule, Content } = state;
  const toggle = () => {
    !isToggle ? document.body.classList.add('fixed') : document.body.classList.remove('fixed');
    setToggle(!isToggle);
  };
  const fileRef = useRef();
  const checkRef = useRef();
  const addFile = (e) => {
    const file = e.target.files[0];

    if (maxSize < file.size) return alert(maxSizeMb + 'MB 이내의 파일만 가능합니다.');
    setFiles([...files, file]);
  };
  const onSubmit = () => {
    if (!checkRef.current.checked) {
      return alert('약관동의가 필요합니다');
    }
    const formData = new FormData();
    for (let key of Object.keys(state)) {
      formData.append(key, state[key]);
    }
    for (let file of files) {
      formData.append('File', file);
    }
    for (var key of formData.entries()) {
    }
    axios
      .post(`${config.api}/contact`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        reset();
        setFiles([]);
        setIsAgree(false);
        alert('정상적으로 등록되었습니다.');
      })
      .catch(function (error) {
        alert('문제가 발생하였습니다.');
      });
    //**Reset */
  };
  const deleteFile = (i, e) => {
    const N_count = 1;
    const update = [...files];
    update.splice(i, N_count);
    setFiles(update);
  };

  const onFocus = (e) => {
    e.target.parentNode.classList.add('on');
  };

  const onFocusOut = (e) => {
    e.target.parentNode.classList.remove('on');
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <comp.Bg style={{ zIndex: '-1' }} bg={'nothing'} />
      <comp.SideBar top={props.top} color={'rgba(255, 255, 255, 0.1)'} />
      {isToggle ? undefined : <comp.Floating top={props.top} />}
      <comp.Privacy parentCallback={toggle} isToggle={isToggle} />
      <div className="olive contact">
        <comp.Animate top={props.top} animationName="fadeInUp">
          <div className="olive-content">
            <div className="slogun left">
              <div className="desc_wrap">
                <div className="desc">
                  올리브스톤에게 의뢰할 <br />
                  프로젝트가 있으세요? <br />
                  그럼 함께 시작해보세요.
                </div>
              </div>
              <div className="desc_wrap">
                <div className="content">
                  <div className="contact-slogun">
                    We look forward to working with you on your next project. <br />
                    Let's get started!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </comp.Animate>
        <div className="work-container">
          <div className="work-content">
            <comp.Animate top={props.top} animationName="fadeInUp slower">
              <div className="form-container f1">
                <div className="form-gr">
                  <div className="desc_wrap">
                    <div className="title">프로젝트 의뢰전 몇가지만 작성해 주실수 있나요?</div>
                  </div>
                  <div className="oliveinput">
                    <div className="label">이름</div>
                    <div className="input-area">
                      <div className="oliveinput-container">
                        <input
                          type="text"
                          name="Name"
                          placeholder="이름"
                          maxLength={50}
                          value={Name}
                          onChange={onChange}
                          autoComplete="off"
                          onFocus={onFocus}
                          onBlur={onFocusOut}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="oliveinput">
                    <div className="label">기업/단체명</div>
                    <div className="oliveinput-container">
                      <input
                        type="text"
                        name="Business"
                        placeholder="기업/단체명"
                        maxLength={50}
                        value={Business}
                        onChange={onChange}
                        autoComplete="off"
                        onFocus={onFocus}
                        onBlur={onFocusOut}
                      />
                    </div>
                  </div>
                  <div className="oliveinput">
                    <div className="label">이메일</div>
                    <div className="oliveinput-container">
                      <input
                        type="text"
                        name="Email"
                        placeholder="이메일"
                        maxLength={100}
                        value={Email}
                        onChange={onChange}
                        autoComplete="off"
                        onFocus={onFocus}
                        onBlur={onFocusOut}
                      />
                    </div>
                  </div>
                  <div className="oliveinput">
                    <div className="label">연락처</div>
                    <div className="oliveinput-container">
                      <input type="text" name="Tel" placeholder="연락처" maxLength={50} value={Tel} onChange={onChange} autoComplete="off" onFocus={onFocus} onBlur={onFocusOut} />
                    </div>
                  </div>
                </div>
              </div>
            </comp.Animate>
            <comp.Animate top={props.top} animationName="fadeInUp slower">
              <div className="form-container f2">
                <div className="desc_wrap">
                  <div className="title">예산 및 일정은요?</div>
                </div>
                <div className="form-gr-select">
                  <div>
                    <div className="oliveinput">
                      <div className="label">예상 예산</div>
                      <select onChange={onChange} name="Budget" value={Budget}>
                        <option value={''}>예산을 선택해주세요.</option>
                        <option value={`1000만원 - 5000만원`}>1000만원 - 5000만원</option>
                        <option value={`5000만원 - 1억원`}>5000만원 - 1억원</option>
                        <option value={`1억원 - 3억원`}>1억원 - 3억원</option>
                        <option value={`3억원 - 5억원`}>3억원 - 5억원</option>
                        <option value={`5억원 이상`}>5억원 이상</option>
                        <option value={`미정`}>미정</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="oliveinput">
                      <div className="label">예상 일정</div>
                      <select onChange={onChange} name="Schedule" value={Schedule}>
                        <option value={``}>일정을 선택해주세요.</option>
                        <option value={`3개월 이내`}>3개월 이내</option>
                        <option value={`3-6 개월`}>3-6 개월</option>
                        <option value={`7-9 개월`}>7-9 개월</option>
                        <option value={`10-12개월`}>10-12개월</option>
                        <option value={`1년 이상`}>1년 이상</option>
                        <option value={`미정`}>미정</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </comp.Animate>
            <comp.Animate top={props.top} animationName="fadeInUp slower">
              <div className="form-container f3">
                <div className="desc_wrap">
                  <div className="title">귀를 기울일 준비가 되어있습니다. 조금 더 자세히 적어주실수 있나요?</div>
                </div>
                <div>
                  <textarea placeholder={guideText} onChange={onChange} name="Content" value={Content} maxLength={1000}></textarea>
                  <div className="file-wrap">
                    <label htmlFor="fileHandle" className="label">
                      첨부파일
                    </label>
                    <div className="file-list">
                      {files && files.length > 0
                        ? files.map((r, i) => (
                            <div key={i}>
                              {r.name} <span onClick={(e) => deleteFile(i, e)} />
                            </div>
                          ))
                        : undefined}
                      <input type="file" id="fileHandle" name="fileHandle" ref={fileRef} onChange={addFile} style={{ display: 'none' }}></input>
                    </div>
                  </div>
                </div>
                <div className="checkbox-wrap">
                  <div>
                    <label className="containerr">
                      <input type="checkbox" ref={checkRef} checked={isAgree} onChange={() => setIsAgree(!isAgree)} />
                      <span className="checkmark"></span>
                    </label>
                    <div className="policy modal-container">
                      <span onClick={toggle}>개인정보보호 수집 및 이용약관</span>에 동의합니다.<span>*</span>
                    </div>
                  </div>
                  <div className="submit" onClick={onSubmit}>
                    문의하기
                  </div>
                </div>
              </div>
            </comp.Animate>
          </div>
        </div>
        <comp.Footer hidden={true} hide={true} />
      </div>
    </>
  );
};

export default Contact;
