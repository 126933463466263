export default {
    context: null,
    isNext: false,
    isPrev: false,
    isMime: [],
    domain: 'https://api.olivestonelab.com',
    api: 'https://api.olivestonelab.com',
    blog: 'https://blog.naver.com/olivestone12',
    sixth: [
          { data: 'Branding' },
          { data: 'User Research' },
          { data: 'UI,UX Consulting' },
          { data: 'GUI Design' },
          { data: 'Interaction Motion' },
          { data: 'Motion Experience' },
          { data: 'Development' }
        ],
    partners: [
          { img: 'logo-samsung.png', name: 'logo-samsung' },
          { img: 'logo-hyundai.png', name: 'logo-hyundai' },
          { img: 'logo-hyundai-2.png', name: 'logo-hyundai-2' },
          { img: 'logo-kt.png', name: 'logo-kt' },
          { img: 'logo-hyundaimobis.png', name: 'logo-hyundaimobis' },
          { img: 'logo-skmagic.png', name: 'logo-logo-skmagic' },
          { img: 'logo-ericssonlg.png', name: 'logo-ericssonlg' },
          { img: 'logo-skbroadband.png', name: 'logo-skbroadband' },
          { img: 'logo-lottemart.png', name: 'logo-lottemart' },
          { img: 'logo-s-1.png', name: 'logo-s-1' },
          { img: 'logo-ibk.png', name: 'logo-ibk' },
          { img: 'logo-lgcns.png', name: 'logo-lgcns' },
          { img: 'logo-thehyundai.png', name: 'logo-thehyundai' },
          { img: 'logo-bnk.png', name: 'logo-bnk' },
          { img: 'logo-forcs.png', name: 'logo-forcs' },
          { img: 'logo-huawei.png', name: 'logo-huawei' },
          { img: 'logo-tcl.png', name: 'logo-tcl' },
          { img: 'logo-wemakeweplay.png', name: 'logo-wemakeweplay' }
        ],

    nextDelay: 100,
    breakTablet: 1080,
    breakMobile: 770,
    paddingPc: 250,
    paddingMobile: 20,
    paddingTablet: 50,
    /** */
    dummy: {
          ClientName: '11중앙그룹',
          ProjectName: '중앙그룹',
          Title: '중앙그룹',
          StartDate: '2019-08',
          EndDate: '2019-09',
          DirectorMember: 'aaa',
          UiMember: 'bbb',
          GuiMember: 'ccc',
          MotionMember: 'ddd',
          DeveloperMember: 'eee',
          Tag: ['Mobile', 'Web'],
          BackgroundColor: '#FFF2CC',
          CreatedAt: 1584607868876,
          Background: { Mime: 'image/jpeg', Uri: '/2020/03/19/fXQVzWdWWoJOlFdqihkkYSNsEaZlMznO.jpg', Thumbnail: '' },
          Contents: [
                  { Type: 'TXT', Content: '세상과 당신 사이,\n중앙그룹이 있습니다.\n중앙그룹', PickColor: '#d2241b' },
                  {
                            Type: 'TXT',
                            Content:
                              '중앙그룹은 중앙일보, JTBC, 메가박스, 휘닉스 호텔앤드리조트로 \n구성된 그룹입니다.\n중앙그룹은 삶과 문화를 선도하는 기업 이미지 전달과 여러 계열사를 \n하나로 아우르는 강력한 구심점이 필요했습니다.\n\n'
                          },
                  { Type: 'MED', IsPadding: false, File: [{ Mime: 'image/jpeg', Uri: '/2020/03/19/PGvBYYVitskPTPdquIgVfmNzbCyupEAA.jpg' }] },
                  { Type: 'TXT', Content: '중앙그룹은 삶과 문화를 선도하는 기업 이미지 전달과 \n여러 계열사를 하나로 아우르는 강력한 구심점이 필요했습니다.\n\n' },
                  { Type: 'MED', IsPadding: false, File: [{ Mime: 'image/jpeg', Uri: '/2020/03/19/XYffsGEoIxzxnHjVyCvuBugmlPVgtthX.jpg' }] },
                  { Type: 'MED', IsPadding: true, File: [{ Mime: 'image/jpeg', Uri: '/2020/03/19/BBxKgZKfgrygjAVgjVExKHvSSECjZsls.jpg' }] }
                ]
        }
};

