import React from 'react';
import Slider from 'react-slick';
import config from 'config';

export default class OliveSlick extends React.Component {
  state = {
    fy: 0
  };
  render() {
    var settings = {
      accessibility: true,
      autoplay: false,
      // speed: 1400,
      swipeToSlide: false,
      swipe: false,
      touchSlide: false,
      dots: false,
      infinite: false,
      arrows: false,
      className: 'center',
      centerMode: true,
      centerPadding: config.paddingPc,
      slidesToShow: 1,
      slidesToScroll: 1,
      slidesToWheel: 1,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: config.breakTablet,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: config.paddingTablet
          }
        },
        {
          breakpoint: config.breakMobile,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: config.paddingMobile
          }
        }
      ]
    };

    const Data = [
      { id: 1, title: 'Strategy', subtitle: ['브랜딩', '전략', '프로토타입'], desc: ['Branding', 'Starategy', 'Prototype'] },
      {
        id: 2,
        title: 'UX/UI',
        subtitle: ['모바일앱,사이트 구축 및 제작', '가전기기', '디바이스', '웹사이트 구축'],
        desc: ['Mobile Web Site', 'Mobile App', 'Appliance', 'All Device', 'Web Site', 'SI(System Integration']
      },
      { id: 3, title: 'Motion', subtitle: ['모션그래픽', '영상'], desc: ['Motion Graphic', 'Movie'] },
      { id: 4, title: 'Dev', subtitle: [], desc: ['iOS', 'Android', 'Tizen', 'Server', 'Web Develop', 'Web Publishing', 'IoT'] }
    ];

    return (
      <div className="main-slider">
        <div className="sticky-slider">
          <Slider {...settings} className="slick-custom">
            {Data.map((data) => (
              <div className="item" key={data.id}>
                <div>
                  <div className="title">{data.title}</div>
                  <div className="subtitle">
                    {data.subtitle.map((sub, idx) => (
                      <span key={idx}>
                        {sub}
                        <br />
                      </span>
                    ))}
                  </div>
                  <div className="desc">
                    {data.desc.map((sub, idx) => (
                      <span key={idx}>
                        {sub}
                        <br />
                      </span>
                    ))}
                  </div>
                </div>
                <div>
                  <div className="line"></div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}
