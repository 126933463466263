import React, { useState, useRef, useEffect } from 'react';
import { motion, useCycle } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import config from 'config';

const youtubeURL = 'https://www.youtube.com/embed/cZubXXaKUF0?autoplay=1';

const Nav = (props) => {
  const history = useHistory();
  const navBar = useRef();

  const routerMotion = (path) => {
    let linkHref = document.location.href;
    if (linkHref.substr(linkHref.length - path.length, linkHref.length) === path) return;
    document.body.classList.add('off');
    setTimeout(() => {
      history.push(`${path}`);
      document.body.classList.remove('off');
    }, 1000);
  };

  return (
    <div className="nav__container" style={props.isFull ? { bottom: '0' } : null}>
      <div className={'nav__bar fixed'} ref={navBar}>
        <div className="nav__content">
          <div className="nav__logo" onClick={() => routerMotion('/')} />
          <div className="nav__menu">
            <div className="youtube_link" onClick={() => props.toggleYoutube(youtubeURL)}>
              <ul className="animation-wrap">
                <li className="animation">
                  <div className="first"></div>
                  {/* <div className="second"></div>
                  <div className="third"></div>
                  <div className="fourth"></div> */}
                </li>
                <li className="animation"></li>
                <li className="animation"></li>
              </ul>
            </div>
            <div className="link" onClick={() => routerMotion('/work')}>
              Work
            </div>
            <div className="link" onClick={() => routerMotion('/contact')}>
              Contact
            </div>
            <div>
              <a href={config.blog} target="_blank" rel="noopener noreferrer">
                Blog
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NavSide = (props) => {
  // const [isOpen, setOpen] = useState(false);
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [insetValue, setInsetValue] = useState({ inset: 'inset(90% 25px 50px 60%)' });
  const [windowHeight, seWindowHeight] = useState(window.innerHeight);
  const history = useHistory();
  const navSide = useRef();
  const containerRef = useRef(null);
  const toggle = () => {
    navSide.current.style.background = '#fff';
    toggleOpen();
  };

  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.18 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  const variantsList = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 950, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 950 }
      }
    }
  };
  const reSizeHeight = () => {
    const windowInnerHeight = window.innerHeight;
    seWindowHeight(windowInnerHeight);
    setInsetValue({ ...insetValue });
    navSide.current.style.transition = 'none';
    // navSide.current.style.background = 'transparent';
  };

  const touchMove = () => {
    if (isOpen) return;
    navSide.current.style.background = 'transparent';
    if (!isOpen) navSide.current.style.background = 'transparent';
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    window.addEventListener('resize', reSizeHeight);
    window.addEventListener('touchmove', touchMove);

    return () => {
      window.removeEventListener('resize', reSizeHeight);
      window.removeEventListener('touchmove', touchMove);
    };
  });

  // const timer = new Promise((res,rej)=>{
  //   res
  // })

  const handleNav = (e) => {
    e.stopPropagation();
    if (e.target.tagName === 'DIV') return;
    if (e.target.className.indexOf('link') > -1) {
      const link = e.target.getAttribute('value');
      let linkHref = document.location.href;
      if (linkHref.substr(linkHref.length - link.length, linkHref.length) === link) return toggleOpen(false);
      document.body.classList.add('off');
      setTimeout(() => {
        toggleOpen();
      }, 250);
      setTimeout(() => {
        history.push(`${link}`);
        setTimeout(() => {
          document.body.classList.remove('off');
        }, 100);
      }, 900);
    }
  };

  return (
    <>
      <div className={`nav__side__wrap ${isOpen ? 'open' : ''}`}>
        <div className="nav__sdie__overlay" onClick={toggle}></div>
      </div>
      <motion.div
        className={`nav__side__menu_wrap ${isOpen ? 'open' : ''}`}
        style={{ position: 'fixed', right: 0, width: '180px', height: `${windowHeight}px` }}
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        ref={containerRef}>
        <motion.div
          className="nav__side__menu"
          style={{
            background: '#fff',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            clipPath: isOpen ? `inset(0px 0px 0px 0px)` : `inset(90% 25px 50px 60%)`,
            transition: isOpen ? `0.3s ease 0.1s` : `0.3s ease 0.4s`
          }}
          ref={navSide}
          onClick={handleNav}>
          <motion.ul className="nav__side__content" variants={variants}>
            <motion.li variants={variantsList}>
              <span className="logo link" value="/" />
            </motion.li>
            <motion.li variants={variantsList}>
              <div className="youtube_link" onClick={() => props.toggleYoutube(youtubeURL)}>
                <ul className="animation-wrap">
                  <li className="animation"></li>
                  <li className="animation"></li>
                  <li className="animation"></li>
                </ul>
              </div>
            </motion.li>
            <motion.li variants={variantsList}>
              <span className="link" value="/work">
                Work
              </span>
            </motion.li>
            <motion.li variants={variantsList}>
              <span className="link" value="/contact">
                Contact
              </span>
            </motion.li>
            <motion.li variants={variantsList}>
              <a className="link" href={config.blog} target="_blank" rel="noopener noreferrer">
                Blog
              </a>
            </motion.li>
          </motion.ul>
          <div className="nav__side__space"></div>
        </motion.div>
      </motion.div>
      <motion.div className="nav__side__toggle" onClick={toggle} style={{ opacity: isOpen ? '0' : '1', transitionDelay: isOpen ? '0.1s' : '0.3s', zIndex: 9000 }}></motion.div>
    </>
  );
};

export default { Nav, NavSide };
