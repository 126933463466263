import { useEffect, useState } from 'react';
import axios from 'axios';

const useRequest = (url) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await axios.get(url);
        setResponse(res);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [url]);
  return [response, loading, error];
};

export default useRequest;
