import React from 'react';

const Privacy = ({ isToggle, parentCallback }) => {
  return isToggle ? (
    <div className="modal-container">
      <div className="modal center" onClick={parentCallback}>
        <div className="modal-content privacy">
          <h1 className="title lg-only">
            개인정보 수집 및<br />
            이용약관
          </h1>
          <div>
            <h1 className="title md-only">개인정보 수집 및 이용약관</h1>
            <h6>개인정보 수집이용에 대한 동의</h6>
            <p>
              회원님의 소중한 개인정보는 다음과 같은 정책에 따라 수집 및 이용됩니다. <br />
              올리브스톤에서는 목적에 연관되는 개인정보만을 수집하며, 수집된 정보를 투명하고 안전하게 보호 관리할 것을 약속합니다. <br />
              이에 개인정보 수집및 이용에 대한 동의를 구합니다.
            </p>
            <div className="space"></div>
            <h6>개인정보 수집이용에 대한 동의</h6>
            <p>
              회원님의 프로젝트에 대한 견적, 기간, 개발방법 등의 문의에 대한 정보가 보다 정확한 답변을 위해 수집됩니다. <br />
              상시 인력채용을 위한 인재풀 유지를 위해 지원자의 개인정보가 수집됩니다. <br />
              <br />
              수집항목 <br />
              필수항목: 성명, 나이, 회사명, 웹사이트, 연락처, 이메일, 의뢰내용, 예산, 첨부파일 선택항목: 홈페이지 <br />
              보유이용기간 <br />
              원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. <br />
              단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다. <br />
              보존 이유 : 회원님의 동의를 통한 인재정보 유지 보존 기간 : 회원정보 삭제 요청시까지 <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};
export default Privacy;
