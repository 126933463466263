import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import config from 'config';
import comp from 'components';

const Work = (props) => {
  const [arrow, setArrow] = useState(false);
  const { Works } = config.context;
  const history = useHistory();

  const onDetail = (e, url) => {
    const link = e.target.getAttribute('value');
    if (url !== '' && url !== undefined) return props.toggleYoutube(url);
    if (link === null) {
      return;
    }
    document.body.classList.add('off');

    setTimeout(() => {
      history.push(`/detail/${link}`);
      document.body.classList.remove('off');
    }, 1000);
  };

  const mainWheel = (e) => {
    const bodyHeight = document.body.offsetHeight;
    e.pageY >= bodyHeight * 0.75 ? setArrow(true) : setArrow(false);
    // sliderShow(e);
  };

  if (!Works || Works.length === 0) return <div />;
  return (
    <>
      <comp.SideBar top={props.top} color={'rgba(255, 255, 255, 0.1)'} />
      <comp.Floating top={props.top} />
      <div className="olive work" onWheel={mainWheel}>
        <comp.Animate top={props.top} animationName="fadeInUp">
          <div className="olive-content">
            <div className="slogun left">
              <div className="desc_wrap">
                <div className="desc">
                  함께 프로젝트를 진행한 <br />
                  고객의 소중한 자산입니다
                </div>
              </div>
              <div className="desc_wrap">
                <div className="content">
                  Some of out featured work includes collaboration
                  <br />
                  with the world's leading companies
                </div>
              </div>
            </div>
          </div>
        </comp.Animate>

        <div className="work-content">
          {Works.map((r, i) => {
            return (
              r.IsDisplay === 'O' && (
                <comp.Animate top={props.top} animationName="fadeInUp slow" key={i} className="work-item-wrap">
                  <div style={{ display: r.IsDisplay === 'X' && 'none' }}>
                    <div
                      className="work-item"
                      style={{ backgroundImage: `url(${config.domain}${r.Background.Thumbnail ? r.Background.Thumbnail : r.Background.Uri})` }}
                      value={r.Background.Mime ? i : null}
                      onClick={(e) => onDetail(e, r.IsYoutube)}></div>
                    <div className="text-wrap">
                      <div className="text">
                        <div className="desc_wrap">
                          {/* br 태그 추가를 위한 dangerouslySetInnerHTML */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `
              <div class="title" >${r.ClientName.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br>')}</div>
          `
                            }}
                          />
                        </div>
                        <div className="desc_wrap">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `
              <div class="desc" >${r.ProjectName.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br>')}</div>
          `
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </comp.Animate>
              )
            );
          })}
        </div>
        <comp.Footer hide={true} arrow={arrow} />
      </div>
    </>
  );
};

export default Work;
