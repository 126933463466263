import React, { useEffect, useRef } from 'react';
const text = `OLIVESTONE DESIGN AGENCY, WE GROW BUSINESSES BY CREATING EXPERIENCES PEOPLE LOVE`;

const increase = 0.32;
const SideBar = ({ top, color }) => {
  const sidebar = useRef();
  useEffect(() => {}, [top]);
  const calc = increase * top;
  return (
    <div ref={sidebar} className="sidebar" style={{ color: color ? color : '#2bbf66', transform: `translateY(-${calc}px)` }}>
      {text}
    </div>
  );
};

export default SideBar;
